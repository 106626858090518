import Vue from 'vue'
import App from './App.vue'
import { createStore } from './store'

export function createApp(context) {
  const store = createStore(context)

  store.commit('checkout/asyncDataMutations', context)

  // store.subscribe((mutation, state) => {
  //   if (mutation.payload?.checkout) {
  //     console.info(
  //       '%cCheckoutInfo:',
  //       'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff',
  //       JSON.parse(JSON.stringify(state.checkout.checkout)),
  //       '\n'
  //     )
  //   }
  //   if (mutation.payload?.checkoutParams) {
  //     console.info(
  //       '%checkoutParams:',
  //       'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff',
  //       JSON.parse(JSON.stringify(state.checkout.checkout)),
  //       '\n'
  //     )
  //   }
  // })

  const app = new Vue({
    store,
    render: h => h(App)
  })
  return { app }
}
